@import '../Builder/Theming/Theming';
.PublicPageViewWrapper {
  position: relative;
  padding-top: 20px;
  .ProgressBarContent {
    width: 100%;
    max-width: $containerWidth;
    background-color: $formColor;
    padding-bottom: 0;
    margin: 0 auto;
    position: sticky;
    top: 52px;
    z-index: 7;
    &.nonSticky {
      position: relative;
      top: 0;
    }
  }
}
:global(.forPublicPage),
:global(.fbpTabView),
:global(.fbpPhoneView) {
  .ProgressBarContent {
    top: 0;
  }
}
.fbpFormBuilderBodyContent {
  width: 100%;
  max-width: $containerWidth;
  padding: 30px 0;
  margin: 0 auto;
  flex: 1 1;
  .fbpPageContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: $formColor;
    background-image: $formImage;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: $formImageAttachment;
    background-origin: initial;
    background-clip: initial;
    &::before {
      content: "";
      background-color: $formImageColor;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: $formImageOpacity;
    }
  }
  & > div.classicViewContent {
    background: $pageColor;
    padding: 30px 0;
    position: relative;
  }
}
